@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --navbar-height: 95px;
  --movil-navbar-height: 70px;
  --primaryColor: #1b212c;
  --primaryColorDark: #ffffff;
  --secondaryColor: #a58861;
  --secondaryColorDark: #ffd6ae;
  --tertiaryColor: #aaaaaa;
  --tertiaryColorDark: #aaaaaa;
  --fourthColor: #f64b29;
  --quaternaryColor: #909090;
  --quaternaryColorDark: #d9d9d9;
}

* {
  font-family: 'Open Sans', sans-serif;
  @apply text-[color:var(--primaryColor)] dark:text-[color:var(--primaryColorDark)];
}

body {
  @apply bg-[color:var(--primaryColorDark)] dark:bg-[color:var(--primaryColor)];
}

@layer components {
  .primaryColor {
    @apply text-[color:var(--primaryColor)] dark:text-[color:var(--primaryColorDark)];
  }
  .secondaryColor {
    @apply text-[color:var(--secondaryColor)] dark:text-[color:var(--secondaryColorDark)];
  }
  .tertiaryColor {
    @apply text-[color:var(--tertiaryColor)] dark:text-[color:var(--tertiaryColorDark)];
  }
  .quaternaryColor {
    @apply text-[color:var(--quaternaryColor)] dark:text-[color:var(--quaternaryColorDark)];
  }
  .filled {
    @apply bg-white dark:bg-[#3C4657];
  }
  .filled2 {
    @apply bg-[#F2F2F2] dark:bg-[#1B212C];
  }
}

.poppins-font {
  font-family: 'Poppins', sans-serif;
}

.montserrat-font {
  font-family: 'Montserrat', sans-serif;
}

.screen-height {
  @apply h-[calc(100vh-var(--navbar-height))];
}

@layer components {
  .select-language {
    @apply outline-none;
  }
}

.menu-item {
  font-family: 'Poppins', sans-serif;
  color: white;
  font-size: 55px;
}

.menu-item-selected {
  @apply text-[#AAAAAA];
}

.arrow {
  height: 19px;
  color: #1b212c;
  font-weight: 300;
  opacity: 0.4;
}
